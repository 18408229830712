import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import PropsTypes from "prop-types";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "react-dropzone";
import Slider from "react-slick";
import DatePicker from "react-datepicker";
import Select from "react-select";
import arrow from "../../../assets/images/inrovideo/arrow.svg";
import datearrow from "../../../assets/images/game/date-arrow.svg";
import {
  gameEventLogoUpload,
  gameEventbannerupload,
  updateGameEvent,
} from "../../../utils/Api";
import { updateGameEventSchema } from "../../../validationSchema/gameSchema";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";

import fileicon from "../../../assets/images/inrovideo/file.svg";
import cross from "../../../assets/images/inrovideo/cross.svg";
// import refresh from "../../assets/images/inrovideo/refresh.svg";
import alerticon from "../../../assets/images/inrovideo/alert.svg";

const customStyles = {
  option: (provided) => ({
    ...provided,
    background: "#454851",
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    padding: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    ":hover": {
      background: "#4C505E",
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: "#454851",
    padding: "0px",
  }),
  control: () => ({
    background: "#454851",
    border: "1px solid #A0AABB",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItem: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
  }),
};

const options = [
  { value: "GMT", label: "GMT" },
  { value: "IST", label: "IST" },
  //  { value: "PST", label: "PST" },
  { value: "PT", label: "PT" },
  { value: "MT", label: "MT" },
  { value: "CT", label: "CT" },
  { value: "ET", label: "ET" },
];

function GenerateGamePopup({
  game,
  handleDeleteGame,
  handleStepChange,
  handleGameCreateClose,
}) {
  const [isLoading, setIsLoading] = useState("");
  const [seletedFile, setSelectedFile] = useState();
  const [error, setErrors] = useState("");
  const [loading, setLoading] = useState("");
  const [gameBanner, setGameBanner] = useState();
  const [bannerloading, setbannerLoading] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm({ resolver: yupResolver(updateGameEventSchema) });

  const uploadFile = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("gameId", game.id);
      setLoading("loading");
      const res = await gameEventLogoUpload(formData);
      if (!res) {
        setLoading("error");
        return null;
      }
      setSelectedFile(res);
      setValue("logoUrl", res);
    }
    setLoading("");
  };

  const uploadGamebanner = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("gameId", game.id);
      setbannerLoading("loading");
      const res = await gameEventbannerupload(formData);
      if (!res) {
        setLoading("error");
        return null;
      }
      setError("");
      setGameBanner(res);
      setValue("gameBanner", res);
    }
    setbannerLoading("");
  };

  const handleFileSelect = (files) => {
    if (!files[0].type.startsWith("image")) {
      return setErrors("Please select image file");
    }
    if (files[0].size / (1024 * 1024) > 5) {
      return setErrors("File is too large");
    }

    setSelectedFile(files[0]);
    setErrors("");
    uploadFile(files[0]);
  };

  const handleDeleteGameBanner = () => {
    setGameBanner();
    setErrors("");
    setError("gameBanner", "");
    setbannerLoading("");
  };

  const handleGamebannerSelection = (files) => {
    if (!files[0].type.startsWith("image")) {
      return setErrors("Please select image file Only");
    }
    if (files[0].size / (1024 * 1024) > 5) {
      return setErrors("Please Upload Maximum 5 MB Image.");
    }

    setGameBanner(files[0]);
    setErrors("");
    uploadGamebanner(files[0]);
  };

  const handleDeleteFile = () => {
    setSelectedFile();
    setErrors("");
    setError("logoUrl", "");
    setLoading("");
  };
  useEffect(() => {
    // getAllUsers(setUsers);
    setValue("buyin", game?.buyin);
    setValue("smallBlind", game?.smallBlind);
    setValue("scheduledDate", new Date(game?.scheduleDate));
    setValue("time", game.time || "00:00");
    setValue("timezone", game.timezone);
    setValue("noOfTables", game.noOfTables);
    setValue("logoUrl", game?.companyLogo);
    setValue("gameBanner", game?.gameBanner);
  }, [game]);

  const handleDeleteGameData = async () => {
    setIsLoading("delete");
    handleDeleteGame(game?.id);
    setTimeout(() => {
      setIsLoading("");
      handleGameCreateClose();
    }, 1000);
  };

  const handleGameUpdate = async (values) => {
    setIsLoading("save");

    await updateGameEvent(game?.id, {
      ...values,
      title: values?.title,
      logoUrl: seletedFile,
      gameBanner,
    });
    setIsLoading("");
    handleStepChange();
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const settings1 = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  const bigBlindVal = parseInt(watch("smallBlind") * 2, 10) || 0;
  const icreasingBlindMinutes = parseInt(watch("icreasingBlindMinutes"), 10) || 0;
  const scheduledDate = watch("scheduledDate")
    ? new Date(watch("scheduledDate"))
    : new Date(game?.scheduleDate);
  const sceduleTime = watch("time") ? watch("time") : game?.time;
  const noOftables = watch("noOfTables");
  const timezone = watch("timezone");

  const handleNoOfTable = (n) => {
    setValue("noOfTables", n);
  };
  const handleKeyDown = (event) => {
    const disallowedChars = ["-", "+", "e", "E"];
    if (disallowedChars?.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleDateSelect = (date) => {
    setValue("scheduledDate", new Date(date).toISOString());
  };

  const handleTimeSelect = (date) => {
    let h = new Date(date).getHours();
    h = h < 10 ? `0${h}` : h;
    let m = new Date(date).getMinutes();
    m = m < 10 ? `0${m}` : m;
    setValue("time", `${h}:${m}`);
  };
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    const inputElements = document.querySelectorAll("input[type='number']");

    inputElements.forEach((inputElement) => {
      inputElement.addEventListener("wheel", handleScroll, { passive: false });
    });

    return () => {
      inputElements.forEach((inputElement) => {
        inputElement.removeEventListener("wheel", handleScroll);
      });
    };
  }, []);

  return (
    <div className="generate-form-scroll">
      <div className="generate-form generate-step-form">
        <div className="generate-form-head">
          <span
            className="intro-back-arrow"
            onClick={() => handleGameCreateClose()}
            role="presentation"
          >
            <img src={arrow} alt="" />
          </span>
          <h2>Poker Nights</h2>
          {/* <p>Copy the links to go to the game</p> */}
          <p>
            Created on
            {" "}
            {new Date(game?.createdAt).toDateString()}
            {" "}
            {new Date(game?.createdAt).toTimeString()}
          </p>
          {/* <span>Created on {new Date(game.createdAt).toLocaleString()}</span> */}
        </div>
        <Form onSubmit={handleSubmit(handleGameUpdate)}>
          <div className="full-width">
            <Form.Group className="form-group">
              <Form.Label>Game title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Game Name"
                defaultValue={game?.title}
                name="title"
                className={errors.title ? "error-field" : ""}
                {...register("title")}
              />
              {errors?.title ? (
                <p className="error-text">{errors.title.message}</p>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Number of tables</Form.Label>
              <div className="bind-structure table-structure">
                <Slider {...settings1}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
                    <div
                      key={ele}
                      role="presentation"
                      onClick={() => handleNoOfTable(ele)}
                      className={`tables-list ${
                        noOftables === ele ? "selected" : ""
                      }`}
                    >
                      <span>{ele}</span>
                    </div>
                  ))}
                </Slider>
              </div>
              {errors?.noOfTables ? (
                <p className="error-text">Number of table is required</p>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Automatic buy-in (dollars)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Set automatic buy-in amount"
                defaultValue={game?.buyin}
                name="buyin"
                min={0}
                onKeyDown={handleKeyDown}
                className={errors.buyin ? "error-field" : ""}
                {...register("buyin")}
              />
              {errors?.buyin ? (
                <p className="error-text">{errors.buyin.message}</p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className="half-width generate-box">
            <Form.Group className="form-group">
              <Form.Label>Small blind</Form.Label>
              <Form.Control
                type="number"
                placeholder="Set initial small blind"
                defaultValue={game?.smallBlind}
                name="smallBlind"
                onKeyDown={handleKeyDown}
                className={errors.smallBlind ? "error-field" : ""}
                {...register("smallBlind")}
              />
              {errors?.smallBlind ? (
                <p className="error-text">{errors.smallBlind.message}</p>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Big blind</Form.Label>
              <Form.Control
                type="number"
                placeholder="Set automatically"
                name="bigBlind"
                readOnly
                className={errors.bigBlind ? "error-field" : ""}
                {...register("bigBlind")}
                value={bigBlindVal}
              />
              {errors?.bigBlind ? (
                <p className="error-text">{errors.bigBlind.message}</p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className="full-width">
            <Form.Group className="form-group">
              <Form.Label>Increasing blinds (minutes)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Set increasing blinds time interval"
                defaultValue={
                  icreasingBlindMinutes === 0
                    ? "Set increasing blinds time interval"
                    : icreasingBlindMinutes
                }
                name="icreasingBlindMinutes"
                onKeyDown={handleKeyDown}
                className={errors.icreasingBlindMinutes ? "error-field" : ""}
                {...register("icreasingBlindMinutes")}
              />
              {errors?.icreasingBlindMinutes ? (
                <p className="error-text">
                  {errors.icreasingBlindMinutes.message}
                </p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className="full-width">
            <Form.Group className="form-group">
              <Form.Label>Blind structure</Form.Label>
              <div className="bind-structure">
                {icreasingBlindMinutes ? (
                  <Slider {...settings}>
                    {[1, 2, 3, 4, 5, 6].map((ele) => (
                      <div className="bind-list" key={ele}>
                        <h6>
                          {ele * icreasingBlindMinutes}
                          {" "}
                          min
                        </h6>
                        <p>
                          $
                          {(bigBlindVal * ele) / 2}
                          /$
                          {bigBlindVal * ele}
                        </p>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <p>
                    {" "}
                    Enter initial small blind
                    {" "}
                    <br />
                    {" "}
                    and increasing blinds time
                    interval
                  </p>
                )}
              </div>
            </Form.Group>
          </div>
          <div className="full-width ">
            <Form.Group className="form-group">
              <Form.Label>Game date</Form.Label>
              <div className="game-filter-date game-date-box">
                <DatePicker
                  selected={scheduledDate}
                  onChange={(date) => handleDateSelect(date)}
                  placeholderText="MM/DD/YYYY"
                  name="scheduledDate"
                  value={scheduledDate}
                  className={errors.scheduledDate ? "error-field" : ""}
                />
                <img src={datearrow} alt="datearrow" />
              </div>
              {errors?.scheduledDate ? (
                <p className="error-text">{errors.scheduledDate.message}</p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className="half-width time-box">
            <Form.Group className="form-group">
              <Form.Label>Time</Form.Label>
              <div className="game-filter-date game-date-box">
                <DatePicker
                  onChange={(date) => handleTimeSelect(date)}
                  showTimeSelect
                  className={errors.time ? "error-field" : ""}
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="Time"
                  value={sceduleTime}
                />
                <img src={datearrow} alt="datearrow" />
              </div>
              {errors?.time ? (
                <p className="error-text">{errors.time.message}</p>
              ) : (
                ""
              )}
            </Form.Group>
            <div className="time-zone-box">
              <Form.Group className="form-group">
                <Form.Label>Time zone</Form.Label>
                <div className="time-zone-select">
                  <Select
                    classNamePrefix="select"
                    className={errors.timezone ? "error-field" : ""}
                    styles={customStyles}
                    placeholder="Time zone"
                    defaultValue={options.find((el) => el.value === timezone)}
                    options={options}
                    onChange={(v) => setValue("timezone", v.value)}
                  />
                  <div className="game-time-zone">
                    <img src={datearrow} alt="datearrow" />
                  </div>
                </div>
                {errors?.timezone ? (
                  <p className="error-text">{errors.timezone.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
          </div>
          <div className="add-company-logo">
            <h6>
              Upload the company logo. It will be used in the background of each
              players video screen.
            </h6>
            {loading === "loading" ? (
              <FileUploading />
            ) : loading === "error" || error ? (
              <FileUploadingError error={error} />
            ) : seletedFile ? (
              <FileUploadedList
                file={seletedFile}
                handleDeleteFile={handleDeleteFile}
              />
            ) : (
              <FileDropZone onDrop={handleFileSelect} />
            )}
            {errors?.logoUrl ? (
              <p className="error-text">{errors.logoUrl.message}</p>
            ) : (
              ""
            )}
          </div>
          <div className="add-company-logo">
            <h6>Upload the Game Banner</h6>
            {bannerloading === "loading" ? (
              <FileUploading />
            ) : bannerloading === "error" || error ? (
              <FileUploadingError error={error} />
            ) : gameBanner ? (
              <FileUploadedList
                file={gameBanner}
                handleDeleteFile={handleDeleteGameBanner}
              />
            ) : (
              <FileDropZone onDrop={handleGamebannerSelection} />
            )}
            {errors?.gameBanner ? (
              <p className="error-text">{errors.gameBanner.message}</p>
            ) : (
              ""
            )}
          </div>
          <div className="generate-btn">
            <Button type="submit">
              {isLoading === "save" ? (
                <Spinner animation="border" />
              ) : (
                "Continue"
              )}
            </Button>
            <Button onClick={handleDeleteGameData}>
              {isLoading === "delete" ? (
                <Spinner animation="border" />
              ) : (
                "Remove"
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

GenerateGamePopup.propTypes = {
  game: PropsTypes.object.isRequired,
  handleGameCreateClose: PropsTypes.func.isRequired,
  handleDeleteGame: PropsTypes.func.isRequired,
  handleStepChange: PropsTypes.func.isRequired,
};

export default GenerateGamePopup;

function FileDropZone({ onDrop }) {
  return (
    <Dropzone
      onDrop={onDrop}
      maxFiles={1}
      accept={{
        "image/*": [".jpg", ".png", ".jpeg", ".webp"],
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className="intro-drag-video" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              <span>
                <img src={fileicon} alt="" />
                {" "}
                Add file
                {" "}
              </span>
              or drag&drop here
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

FileDropZone.propTypes = {
  onDrop: PropsTypes.func.isRequired,
};
function FileUploading() {
  return (
    <section className="intro-uploading-video">
      <span className="intro-uploading-close">
        <img src={cross} alt="" />
      </span>
      <p>
        <span>
          <Spinner animation="border" />
        </span>
        Uploading...
      </p>
    </section>
  );
}

function FileUploadingError({ error }) {
  return (
    <section className="intro-uploading-video-error">
      <div className="intro-uploading-error-content">
        <img src={alerticon} alt="" />
        <p>Uploading error</p>
        <span>{error}</span>
      </div>
    </section>
  );
}

FileUploadingError.propTypes = {
  error: PropsTypes.string.isRequired,
};

function FileUploadedList({ file, handleDeleteFile }) {
  //  const getFileSize = () => {
  //    let size = "";
  //    let fileSize = 0;
  //    if (typeof file === "string") {
  //      const xhr = new XMLHttpRequest();
  //      xhr.open("GET", file, true);
  //      xhr.responseType = "arraybuffer";
  //      xhr.onreadystatechange = (req, data) => {
  //        fileSize = data.currentTarget.response.byteLength;
  //        if (fileSize / 1000000 > 0.1) {
  //          size = `${(fileSize / 1000000).toFixed(
  //            2
  //          )} MB`;
  //        } else {
  //          size = `${(fileSize / 1000).toFixed(2)} KB`;
  //        }
  //      };
  //      xhr.send(null);
  //    } else {
  //      fileSize = file.size || {};
  //      if (fileSize / 1000000 > 0.1) {
  //        size = `${(fileSize / 1000000).toFixed(
  //          2
  //        )} MB`;
  //      } else {
  //        size = `${(fileSize / 1000).toFixed(2)} KB`;
  //      }
  //    }

  //    return size;
  //  };
  return (
    <section className="intro-uploading-video-list">
      <div className="intro-uploading-list-content">
        <img
          src={typeof file === "string" ? file : URL.createObjectURL(file)}
          alt=""
        />
        <div className="intro-uploading-list-info">
          <p>{typeof file === "string" ? file.split("/").pop() : file?.name}</p>
          {/* <span>{getFileSize()}</span> */}
        </div>
      </div>
      <div
        className="intro-uploading-list-delete"
        onClick={handleDeleteFile}
        role="presentation"
      >
        <span>Delete</span>
      </div>
    </section>
  );
}
FileUploadedList.propTypes = {
  file: PropsTypes.any.isRequired,
  handleDeleteFile: PropsTypes.func.isRequired,
};
