import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../../assets/images/dealer/logo.svg";
import "./style.css";
import { CheckPlayerEmailSchema } from "../../validationSchema/profileSchema";
import UserContext from "../../context/UserContext";
import { addAlternativePlayersID, checkGamePlayer } from "../../utils/Api";

function CheckGameEvent() {
  const { eventId } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({ resolver: yupResolver(CheckPlayerEmailSchema) });

  const handleCheckGamePlayer = async (id, email) => {
    const data = {
      gameEventID: id,
      emailID: email,
    };
    try {
      const res = await checkGamePlayer(data);
      const gameData = res?.data[0] || {};
      if (
        Object.keys(gameData).length > 0
        && gameData?.gameID
        && (gameData?.playerRole === "user" || gameData?.playerRole === "host")
      ) {
        navigate(`/user/main-game/${gameData?.gameID}`);
      } else if (
        Object.keys(gameData).length > 0
        && gameData?.gameID
        && (gameData?.playerRole === "admin" || gameData?.playerRole === "dealer")
      ) {
        navigate(`/dealer/companion/${gameData?.gameID}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error>>", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchPlayer = async (event) => {
    setIsSearching(true);
    const payload = {
      gameEventID: eventId,
      emailID: user?.email,
      alternativeEmailID: event.email,
    };
    try {
      setIsLoading(true);
      const res = await addAlternativePlayersID(payload);
      const { data, message } = res;
      const gameData = data[0] || {};
      if (message) {
        setError(
          "email",
          {
            type: "custom",
            message:
              "We couldn't find your seat, please contact your host for table link",
          },
          { shouldFocus: true }
        );
      } else if (
        Object.keys(gameData).length > 0
        && gameData?.gameID
        && gameData?.playerRole === "user"
      ) {
        navigate(`/user/main-game/${gameData?.gameID}`);
      } else if (
        Object.keys(gameData).length > 0
        && gameData?.gameID
        && (gameData?.playerRole === "dealer" || gameData?.playerRole === "admin")
      ) {
        navigate(`/dealer/companion/${gameData?.gameID}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error>>", error);
      setIsSearching(false);
    } finally {
      setIsSearching(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (eventId && user?.email) {
      handleCheckGamePlayer(eventId, user?.email);
    }
  }, []);
  return (
    <div className="signup check-game-event-page">
      <div className="logo-icon">
        <Link to="/">
          <img src={logo} alt="cpn logo" />
        </Link>
      </div>
      <div className="container">
        <div className="signup-content">
          {isLoading ? (
            <div className="signup-form">
              <div className="signup-form-icon">
                <img src={logo} alt="Logo" />
              </div>
              <h3>Checking...</h3>
            </div>
          ) : (
            <div className="signup-form">
              <div className="signup-form-icon">
                <img src={logo} alt="" />
              </div>
              <p>
                We couldn&apos;t find your seat, please enter the email where
                you received your calendar invite.
              </p>
              <div className="signup-form-content-wrapper">
                <Form
                  className="signup-form-wrapper"
                  onSubmit={handleSubmit(handleSearchPlayer)}
                >
                  <div className="full-width">
                    <Form.Group className="form-group">
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        className={errors.email ? "error-field" : ""}
                        {...register("email")}
                      />
                      {errors?.email ? (
                        <p
                          className="error-text"
                          style={{ textAlign: "left", fontStyle: "italic" }}
                        >
                          {errors.email.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>

                  <div className="signup-btn">
                    <Button
                      className="bg-btn"
                      type="submit"
                      disabled={
                        isSearching
                        || errors?.email?.message.includes("contact your host")
                      }
                    >
                      {isSearching ? <Spinner animation="border" /> : "Search"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckGameEvent;
